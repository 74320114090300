import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { oo } from "../theme";

const LayoutMainContainer = styled.main`
  height: 100%;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  padding: ${oo.verticalSpacing}rem ${oo.horizontalSpacing}rem;
  
  display: flex; 
  flex-flow: column nowrap;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
`;

/** Page-wide base layout for content elements */
const LayoutMain = (props) => {
  const { children, alignItems, justifyContent, backgroundColor, color } = props;
  return (
    <LayoutMainContainer alignItems={alignItems}
                         justifyContent={justifyContent}
                         backgroundColor={backgroundColor}
                         color={color}>
      {children}
    </LayoutMainContainer>
  );
};

LayoutMain.defaultProps = {
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "inherit",
  color: "inherit",
};
LayoutMain.propTypes = {
  children: PropTypes.node.isRequired,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

export default LayoutMain;
