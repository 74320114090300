import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { oo } from "../theme";

const LayoutColumnContainer = styled.div`
  padding: ${props => props.paddingTop}rem 0 ${props => props.paddingBottom}rem 0;  
  max-width: ${props => props.maxWidth}rem;
  width: ${props => props.width};
  text-align: ${props => props.textAlign};
  
  display: flex;
  flex-flow: column nowrap;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
`;

/** Acts as a container for laying out content in a column. */
const LayoutColumn = (props) => {
  const {
    children, alignItems, justifyContent, textAlign, maxWidth, width, paddingTop, paddingBottom,
  } = props;
  return (
    <LayoutColumnContainer alignItems={alignItems}
                           justifyContent={justifyContent}
                           textAlign={textAlign}
                           maxWidth={maxWidth}
                           width={width}
                           paddingTop={paddingTop}
                           paddingBottom={paddingBottom}>
      {children}
    </LayoutColumnContainer>
  );
};

LayoutColumn.defaultProps = {
  alignItems: "center",
  justifyContent: "center",
  textAlign: "left",
  maxWidth: oo.contentWidth,
  width: "auto",
  paddingTop: 0,
  paddingBottom: 0,
};
LayoutColumn.propTypes = {
  children: PropTypes.node.isRequired,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  textAlign: PropTypes.string,
  maxWidth: PropTypes.number,
  width: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

export default LayoutColumn;
